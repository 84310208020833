var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from "styled-components";
import tg from "../../../../assets/img/tg_without_backgroung.svg";
import { useMediaQuery } from "react-responsive";
var FollowUsBox = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-top: 30px;\n  box-shadow: var(--blue-btn);\n  border-radius: 24px;\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n"], ["\n  margin-top: 30px;\n  box-shadow: var(--blue-btn);\n  border-radius: 24px;\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n"])));
var Container = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 100%;\n  display: flex;\n  gap: 20px;\n  align-items: center;\n  justify-content: center;\n"], ["\n  width: 100%;\n  display: flex;\n  gap: 20px;\n  align-items: center;\n  justify-content: center;\n"])));
var Link = styled.a(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex ;\n  align-items:center;\n  cursor: pointer;\n\n  &:hover,\n  &:focus {\n      filter: invert(70%) sepia(45%) saturate(557%) hue-rotate(176deg) brightness(90%) contrast(90%);\n      opacity:0.6;\n  }\n"], ["\n  display: flex ;\n  align-items:center;\n  cursor: pointer;\n\n  &:hover,\n  &:focus {\n      filter: invert(70%) sepia(45%) saturate(557%) hue-rotate(176deg) brightness(90%) contrast(90%);\n      opacity:0.6;\n  }\n"])));
var Message = styled.span(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  white-space: nowrap;\n  text-decoration: none;\n  font-size: 12px;\n  line-height: 14px;\n  font-weight: 400;\n  color: var(--main-Gray-3-color);\n  height: 14px;\n  margin-right:12px;\n  cursor: pointer;\n"], ["\n  white-space: nowrap;\n  text-decoration: none;\n  font-size: 12px;\n  line-height: 14px;\n  font-weight: 400;\n  color: var(--main-Gray-3-color);\n  height: 14px;\n  margin-right:12px;\n  cursor: pointer;\n"])));
var Icon = styled.img(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n   color: var(--main-Gray-3-color);\n   opacity:0.7;\n"], ["\n   color: var(--main-Gray-3-color);\n   opacity:0.7;\n"])));
var icons_arr = [
    // {
    //   id: 1,
    //   name: "facebook",
    //   link: "https://www.facebook.com/",
    //   icon: fb,
    // },
    // {
    //   id: 2,
    //   name: "twitter",
    //   link: "https://www.twitter.com/",
    //   icon: tw,
    // },
    // {
    //   id: 3,
    //   name: "vk",
    //   link: "https://www.vk.com/",
    //   icon: vk,
    // },
    {
        id: 4,
        name: "telegram",
        link: "https://t.me/itnews_pro",
        icon: tg,
    },
    // {
    //   id: 5,
    //   name: "linkedin",
    //   link: "https://www.telegramm.com/",
    //   icon: ld,
    // },
];
export var FollowUsBlock = function () {
    var isPlanshet = useMediaQuery({
        query: "(max-width: 960px)",
    });
    return (_jsx(FollowUsBox, { children: _jsx(Container, __assign({ paddingleft: isPlanshet ? "35px" : "15px" }, { children: icons_arr === null || icons_arr === void 0 ? void 0 : icons_arr.map(function (icon) {
                return (_jsxs(Link, __assign({ href: icon.link }, { children: [_jsx(Message, { children: "\u041C\u044B \u0432 Telegram" }), _jsx(Icon, { src: icon.icon, alt: icon.name })] })));
            }) })) }));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
