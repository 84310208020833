var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "react-day-picker/dist/style.css";
import { format } from "date-fns";
import style from "./calendarMini.module.scss";
import { useState, useRef } from "react";
import { ru } from "date-fns/locale";
import { DayPicker, useNavigation } from "react-day-picker";
import { RiArrowLeftSLine, RiArrowRightSLine } from "react-icons/ri";
import { AiOutlineCalendar } from "react-icons/ai";
import { usePopper } from "react-popper";
import FocusTrap from "focus-trap-react";
import dayjs from "dayjs";
import { StyledCalendarMini } from "./styledCalendarMini";
import "react-day-picker/dist/style.css";
import InputMask from "react-input-mask";
import styles from "react-day-picker/dist/style.module.css";
var CalendarMiniEvents = function (_a) {
    var isCalendarStart = _a.isCalendarStart, name = _a.name, width = _a.width, setFieldValueFormik = _a.setFieldValueFormik, inputValueStart = _a.inputValueStart, setInputValueStart = _a.setInputValueStart, inputValueEnd = _a.inputValueEnd, setInputValueEnd = _a.setInputValueEnd, selectedDayStart = _a.selectedDayStart, setSelectedDayStart = _a.setSelectedDayStart, selectedDayEnd = _a.selectedDayEnd, setSelectedDayEnd = _a.setSelectedDayEnd;
    var currentDate = dayjs();
    var _b = useState(currentDate), date = _b[0], setDate = _b[1];
    var classNames = __assign(__assign({}, styles), { table: style.table, day: style.day, tbody: style.tbody, head: style.head, cell: style.cell, head_row: style.header_row, head_cell: style.header_cell, day_today: style.day_today, day_selected: style.day_selected });
    var footer = (_jsxs("div", __assign({ className: style.calendar_footer }, { children: [_jsx("p", __assign({ className: style.calendar_footer_today, onClick: function (e) {
                    e.preventDefault();
                    e.stopPropagation();
                    if (isCalendarStart) {
                        setSelectedDayStart(new Date());
                    }
                    else {
                        setSelectedDayEnd(new Date());
                    }
                    handleDaySelect(new Date());
                } }, { children: "\u0421\u0435\u0433\u043E\u0434\u043D\u044F" })), _jsx("p", __assign({ className: style.calendar_footer_delete, onClick: function (e) {
                    e.preventDefault();
                    e.stopPropagation();
                    if (isCalendarStart) {
                        setSelectedDayStart(undefined);
                    }
                    else {
                        setInputValueEnd("");
                    }
                    handleDaySelect(undefined);
                } }, { children: "\u0423\u0434\u0430\u043B\u0438\u0442\u044C" }))] })));
    var calendarStyles = "\n    .my-selected:not([disabled]){\n            background-color: #1482C5;\n            border-radius: 4px;\n            color: #fff;\n        }\n    .rdp-day {\n        font-weight: 500;\n        font-size: 14px;\n        line-height: 22px;\n        display: flex;\n        align-items: center;\n        text-align: center;\n        letter-spacing: 0.22px;\n        color: #333333;\n        border-radius: 4px;\n    }\n    .rdp-day:hover {\n        color: #fff;\n      }\n      .rdp {\n        --rdp-accent-color: rgba(20, 130, 197, 0.6);\n        --rdp-background-color: rgba(20, 130, 197, 0.6);\n        /* Outline border for focused elements */\n        --rdp-outline: 2px solid var(--rdp-accent-color);\n        /* Outline border for focused and selected elements */\n        --rdp-outline-selected: 2px solid rgba(0, 0, 0, 0.75);\n      }\n      .rdp-head_cell {\n        font-family: 'Roboto';\n        font-style: normal;\n        font-weight: 700;\n        font-size: 10px;\n        line-height: 14px;\n        text-align: center;\n        letter-spacing: 1px;\n        text-transform: uppercase;\n        color: #000000;\n        margin-bottom: 22px;\n      }\n      ";
    var CustomCaption = function (props) {
        var _a = useNavigation(), goToMonth = _a.goToMonth, nextMonth = _a.nextMonth, previousMonth = _a.previousMonth;
        return (_jsxs("h2", __assign({ className: style.calendar_header }, { children: [_jsx("button", __assign({ type: "button", disabled: !previousMonth, onClick: function () { return previousMonth && goToMonth(previousMonth); }, className: style.calendar_header_prev }, { children: _jsx(RiArrowLeftSLine, {}) })), format(props.displayMonth, "LLLL YYY", { locale: ru }), _jsx("button", __assign({ type: "button", disabled: !nextMonth, onClick: function () { return nextMonth && goToMonth(nextMonth); }, className: style.calendar_header_next }, { children: _jsx(RiArrowRightSLine, {}) }))] })));
    };
    var _c = useState(false), isPopperOpen = _c[0], setIsPopperOpen = _c[1];
    var popperRef = useRef(null);
    var buttonRef = useRef(null);
    var _d = useState(null), popperElement = _d[0], setPopperElement = _d[1];
    var popper = usePopper(popperRef.current, popperElement, {
        placement: "bottom-start",
    });
    var closePopper = function () {
        var _a;
        setIsPopperOpen(false);
        (_a = buttonRef === null || buttonRef === void 0 ? void 0 : buttonRef.current) === null || _a === void 0 ? void 0 : _a.focus();
    };
    var handleInputChange = function (e) {
        if (setInputValueEnd) {
            setInputValueEnd(e.currentTarget.value);
            setFieldValueFormik(name, e.currentTarget.value);
        }
        else {
            setInputValueStart(e.currentTarget.value);
            setFieldValueFormik(name, e.currentTarget.value);
        }
        var isDateValid = dayjs(e.target.value).isValid();
        if (isDateValid === true && e.target.value.length === 10) {
            if (isCalendarStart) {
                setSelectedDayStart(dayjs(e.target.value).toDate());
            }
            else {
                setSelectedDayEnd(dayjs(e.target.value).toDate());
            }
            setDate(dayjs(e.target.value));
        }
    };
    var handleButtonClick = function () {
        setIsPopperOpen(true);
    };
    var handleDaySelect = function (date) {
        if (isCalendarStart) {
            setSelectedDayStart(date);
        }
        else {
            setSelectedDayEnd(date);
        }
        setDate(dayjs(date));
        if (date) {
            setInputValueStart ? setInputValueStart(format(date, "y-MM-dd")) : setInputValueEnd(format(date, "y-MM-dd"));
            if (setFieldValueFormik) {
                //formik setFieldValue, name = formik field
                setFieldValueFormik(name, format(date, "y-MM-dd"));
            }
            closePopper();
        }
        else {
            setInputValueStart("");
            setInputValueEnd("");
        }
    };
    //@ts-ignore
    return (_jsx(StyledCalendarMini, { children: _jsxs("div", __assign({ className: style.calendar_mini }, { children: [_jsx("div", __assign({ ref: popperRef, className: style.calendar_wrap_inputs }, { children: _jsxs(StyledCalendarMini, __assign({ width: width }, { children: [_jsx(InputMask, { value: isCalendarStart ? inputValueStart : inputValueEnd, onChange: handleInputChange, name: name, id: name, mask: "9999-99-99", className: "calendar_input", alwaysShowMask: true }), _jsx("button", __assign({ ref: buttonRef, type: "button", className: style.calendar_btn_icon, "aria-label": "Pick a date", onClick: handleButtonClick }, { children: _jsx(AiOutlineCalendar, {}) }))] })) })), isPopperOpen && (_jsx(FocusTrap, __assign({ className: style.calendar_focusTrap, active: true, focusTrapOptions: {
                        initialFocus: false,
                        allowOutsideClick: true,
                        clickOutsideDeactivates: true,
                        onDeactivate: closePopper,
                        fallbackFocus: buttonRef.current,
                    } }, { children: _jsxs("div", __assign({ tabIndex: -1, style: popper.styles.popper, className: style.dialog_sheet }, popper.attributes.popper, { ref: setPopperElement, role: "dialog" }, { children: [_jsx("style", { children: calendarStyles }), _jsx(DayPicker, { mode: "single", selected: isCalendarStart ? selectedDayStart : selectedDayEnd, showOutsideDays: true, classNames: classNames, modifiersClassNames: {
                                    selected: "my-selected",
                                    today: "my-today",
                                }, locale: ru, ISOWeek: true, components: {
                                    Caption: CustomCaption,
                                }, footer: footer, onSelect: handleDaySelect })] })) })))] })) }));
};
export default CalendarMiniEvents;
