var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import style from "./vacancyFilterForMobile.module.css";
import { useAppDispatch } from "../../redux/store";
import Search from "../../assets/img/Search.png";
import { useFormik } from "formik";
import { setActive } from "../../redux/modalReducer";
import { useSelector } from "react-redux";
import { setCheckedFilterToDefault, setCheckedInVacancyFilter, setVacancyFilterParameters, vacancyFilterActivated, } from "../../redux/vacanciesReduser";
import { useNavigate, useLocation } from "react-router-dom";
import { AiOutlineCheck } from "react-icons/ai";
import { useState } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { useMediaQuery } from "react-responsive";
export var VacanciesFilterForMobile = function () {
    var _a, _b;
    var dispatch = useAppDispatch();
    var navigate = useNavigate();
    var location = useLocation();
    var handleCloseFilter = function () {
        dispatch(setActive(false));
    };
    var isPlanshet = useMediaQuery({
        query: "(min-width: 1241px)",
    });
    if (isPlanshet) {
        handleCloseFilter();
    }
    var filterData = useSelector(function (state) { return state.vacancies.allFilter; });
    var categoriesForFilter = useSelector(function (state) { return state.vacancies.categoriesForFilter; });
    var startValues = useSelector(function (state) { return state.vacancies.vacancyFilterParameters; });
    var _c = useState(startValues.form__name && startValues.form__name !== "" ? startValues.form__name : "Ничего не выбрано"), typeSelect = _c[0], setTypeSelect = _c[1];
    var _d = useState(false), openTypeSelect = _d[0], setOpenTypeSelect = _d[1];
    var _e = useState(startValues.city__name && startValues.city__name !== "" ? startValues.city__name : "Ничего не выбрано"), citySelect = _e[0], setCitySelect = _e[1];
    var _f = useState(false), openCitySelect = _f[0], setOpenCitySelect = _f[1];
    var initialValues = {
        word: startValues.word,
        form__name: startValues.form__name,
        city__name: startValues.city__name,
        category__name__in: startValues.category__name__in,
    };
    var onSubmit = function (values, actions) {
        //проверка на то что поле для фильтра не пуста строка
        var valuesToPost = Object.fromEntries(Object.entries(values).map(function (_a) {
            var key = _a[0], value = _a[1];
            // @ts-ignore
            if (value !== "" && (value === null || value === void 0 ? void 0 : value.length) >= 1) {
                return [key, value];
            }
            else {
                return [];
            }
        }));
        dispatch(setVacancyFilterParameters(__assign({}, valuesToPost)));
        dispatch(vacancyFilterActivated(true));
        dispatch(setActive(false));
    };
    var _g = useFormik({
        initialValues: initialValues,
        onSubmit: onSubmit,
        enableReinitialize: true,
    }), values = _g.values, handleBlur = _g.handleBlur, handleChange = _g.handleChange, handleSubmit = _g.handleSubmit, resetForm = _g.resetForm, setValues = _g.setValues, setFieldValue = _g.setFieldValue;
    var handleFilterTriggerOff = function () {
        resetForm();
        dispatch(setVacancyFilterParameters({}));
        dispatch(setCheckedFilterToDefault());
        dispatch(vacancyFilterActivated(false));
        handleChange("");
        setTypeSelect("Ничего не выбрано");
        setCitySelect("Ничего не выбрано");
        values.word = "";
    };
    var checkIsFilterValid = function (values) {
        var oneOfIsChecked = [];
        Object.values(values).forEach(function (obj) {
            if (obj !== "" && (obj === null || obj === void 0 ? void 0 : obj.length) >= 1) {
                oneOfIsChecked.push(true);
            }
            else {
                oneOfIsChecked.push(false);
            }
        });
        if (Object.keys(values).length >= 1 && oneOfIsChecked.includes(true)) {
            return true;
        }
        else {
            return false;
        }
    };
    var returnPageVacancy = function () {
        if (location.pathname === "/companies_page/") {
            return navigate("/vacancy/");
        }
        else if (location.pathname !== ("/vacancy/" || "/companies_page/")) {
            return navigate(-1);
        }
        else
            return;
    };
    if (location.pathname !== "/companies_page/" && location.pathname !== "/vacancy/") {
        handleCloseFilter();
    }
    return (_jsx(_Fragment, { children: _jsx("div", __assign({ className: style.filter }, { children: _jsxs("form", __assign({ className: style.form, autoComplete: "off", onSubmit: handleSubmit }, { children: [_jsxs("div", __assign({ className: style.filter__content }, { children: [_jsxs("div", __assign({ className: style.container_input }, { children: [_jsx("div", { children: _jsx("img", { className: style.icon_input, src: Search }) }), _jsx("input", { id: "word", onChange: handleChange, className: style.input_Search, placeholder: "\u041F\u043E\u0438\u0441\u043A \u043F\u043E \u0437\u0430\u0433\u043E\u043B\u043E\u0432\u043A\u0443 \u0432\u0430\u043A\u0430\u043D\u0441\u0438\u0438", value: values.word ? values.word : "", type: "text" })] })), _jsx("span", __assign({ className: style.filter__parametr }, { children: filterData === null || filterData === void 0 ? void 0 : filterData.name_1 })), _jsxs("div", __assign({ className: style.select_wrap, onClick: function () {
                                    setOpenTypeSelect(!openTypeSelect);
                                    setOpenCitySelect(false);
                                } }, { children: [_jsxs("div", __assign({ className: style.select_current_name }, { children: [_jsx("p", { children: typeSelect }), openTypeSelect ? _jsx(IoIosArrowUp, {}) : _jsx(IoIosArrowDown, {})] })), openTypeSelect && (_jsx("ul", __assign({ className: style.select_list }, { children: openTypeSelect &&
                                            ((_a = filterData === null || filterData === void 0 ? void 0 : filterData.list_1) === null || _a === void 0 ? void 0 : _a.map(function (type, index) {
                                                return (_jsx("input", { type: "button", defaultValue: type.name, id: "form__name", className: type.name === typeSelect ? style.select_input__active : style.select_input, name: "form__name", onBlur: handleBlur, onClick: function () {
                                                        setOpenTypeSelect(false);
                                                        setFieldValue("form__name", "".concat(type.name));
                                                        setTypeSelect(type.name);
                                                    } }, index));
                                            })) })))] })), _jsx("span", __assign({ className: style.filter__parametr }, { children: filterData === null || filterData === void 0 ? void 0 : filterData.name_2 })), _jsxs("div", __assign({ className: style.select_wrap, onClick: function () {
                                    setOpenCitySelect(!openCitySelect);
                                    setOpenTypeSelect(false);
                                } }, { children: [_jsxs("div", __assign({ className: style.select_current_name }, { children: [_jsx("p", { children: citySelect }), openCitySelect ? _jsx(IoIosArrowUp, {}) : _jsx(IoIosArrowDown, {})] })), openCitySelect && (_jsx("ul", __assign({ className: style.select_list }, { children: openCitySelect &&
                                            ((_b = filterData === null || filterData === void 0 ? void 0 : filterData.list_2) === null || _b === void 0 ? void 0 : _b.map(function (city, index) {
                                                return (_jsx("input", { type: "button", defaultValue: city.name, id: "city__name", className: city.name === citySelect ? style.select_input__active : style.select_input, name: "city__name", onBlur: handleBlur, onClick: function () {
                                                        setOpenCitySelect(false);
                                                        setFieldValue("city__name", "".concat(city.name));
                                                        setCitySelect(city.name);
                                                    } }, index));
                                            })) })))] })), _jsxs("div", __assign({ className: style.filter__group_topic }, { children: [_jsx("span", __assign({ className: style.filter__parametr }, { children: filterData === null || filterData === void 0 ? void 0 : filterData.name_3 })), _jsx("div", __assign({ className: style.filter_group_fullCategories }, { children: _jsx("div", __assign({ className: style.scrollableCategories }, { children: categoriesForFilter === null || categoriesForFilter === void 0 ? void 0 : categoriesForFilter.map(function (button, index) { return (_jsx("div", __assign({ className: style.filter__group_topic__checkbox }, { children: _jsxs("label", __assign({ className: style.filter__input_wrap }, { children: [_jsx("input", { className: style.filter__input_checkbox, onChange: function (e) {
                                                                dispatch(setCheckedInVacancyFilter(button));
                                                                handleChange(e);
                                                            }, checked: button.check, onBlur: handleBlur, value: button.name, name: "category__name__in", type: "checkbox" }, button.name), _jsx("span", __assign({ className: style.filter__input_fake }, { children: _jsx(AiOutlineCheck, { className: style.hidden_icon }) })), _jsx("span", __assign({ className: style.filter__input_text }, { children: button.name }))] })) }))); }) })) }))] }))] })), _jsxs("div", __assign({ className: style.filter__buttons }, { children: [checkIsFilterValid(values) ? (_jsx("button", __assign({ type: "submit", className: style.filter__buttons_apply, onClick: function () { return returnPageVacancy(); } }, { children: "\u041F\u0440\u0438\u043C\u0435\u043D\u0438\u0442\u044C \u0444\u0438\u043B\u044C\u0442\u0440" }))) : null, checkIsFilterValid(values) ? (_jsx("button", __assign({ type: "button", onClick: handleFilterTriggerOff, className: style.filter__buttons_clear }, { children: "\u041E\u0447\u0438\u0441\u0442\u0438\u0442\u044C \u0444\u0438\u043B\u044C\u0442\u0440" }))) : null] }))] })) })) }));
};
